import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

// @ts-ignore
export function appBar(theme: Theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  }
}
